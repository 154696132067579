.shipping-support-wrap {
    label {
        display: flex; align-items: center; margin-bottom: 20px;
    }
}

.upload-card {
    .upload-preview {
        width: 100%;
        padding-top: 70%;
        background-color: #ebebeb;
        border-radius: 10px;
        border: 1px dashed #d9d9d9;
        box-sizing: border-box;
        position: relative;
        div {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}