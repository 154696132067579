@import "normalize.css";
//FontFace
@font-face {
    font-family: "SVN-Poppins";
    src: url("../assets/fonts/SVN/SVN-Poppins-Bold.otf");
    font-weight: 700
}
@font-face {
    font-family: "SVN-Poppins";
    src: url("../assets/fonts/SVN/SVN-Poppins-Medium.otf");
    font-weight: 500;
}
@font-face {
    font-family: "SVN-Poppins";
    src: url("../assets/fonts/SVN/SVN-Poppins-Regular.ttf");
    font-weight: 400;
}
@font-face {
    font-family: iCielQuarion;
    src: url("../assets/fonts/iCielQuarion/iCielQuarion-Extralight.woff2") format("woff2"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Extralight.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap
}
@font-face {
    font-family: iCielQuarion;
    src: url("../assets/fonts/iCielQuarion/iCielQuarion-Book.otf") format("opentype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Book.ttf") format("truetype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Book.woff") format("woff"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Book.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}
@font-face {
    font-family: iCielQuarion;
    src: url("../assets/fonts/iCielQuarion/iCielQuarion-Medium.otf") format("opentype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Medium.ttf") format("truetype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Medium.woff") format("woff"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}
@font-face {
    font-family: iCielQuarion;
    src: url("../assets/fonts/iCielQuarion/iCielQuarion-DemiBold.woff2") format("woff2"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-DemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
    font-display: swap
}
@font-face {
    font-family: iCielQuarion;
    src: url("../assets/fonts/iCielQuarion/iCielQuarion-Bold.otf") format("opentype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Bold.ttf") format("truetype"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Bold.woff") format("woff"),
    url("../assets/fonts/iCielQuarion/iCielQuarion-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

:root {
    --font-family:SVN-Poppins, sans-serif;
    --font-header:iCielQuarion, sans-serif;
    --primary: #cc0000;
    --primary-pale: rgba(254, 44, 85, 0.06);
    --wrapper-width:calc(100% - 20px);

    //color
    --primary-code:254,44,85;
    --black-code:0,0,0;
    --white-code:255,255,255;
    --red-code: 254,44,85;
    --blue-code:59, 138, 255;
    --yellow-code:252,203,102;
    --green-code:32, 195, 84;
    --grey-code:133,133,133;

    --black:#000;
    --white:#fff;
    --red: rgba(var(--red-code), 1);
    --blue: rgba(var(--blue-code), 1);
    --green: rgba(var(--green-code), 1);
    --yellow: rgba(var(--yellow-code), 1);
    --grey: rgba(var(--grey-code), 1);


    //Header
    --header-height:50px;
    --header-bg:var(--primary);
    --header-txt-color:#fff;

    --notification-width:400px;
    --notification-message-name:rgba(0,0,0,1);
    --notification-message-des:rgba(0,0,0,0.5);
    //Action Bar
    --actionBar-height:50px;
    --searchBar-height:60px;

    //Modal
    --overlay:rgba(0,0,0,0.5);

    //Form
    --form-heading-color:var(--primary);
    --error-message-color:#fe2c55;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 100%;
    min-height: -webkit-fill-available;
}
body {
    font-family: var(--font-family)!important;
    font-size: 1rem;
    line-height:1.5;
    text-rendering: optimizeSpeed;
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: #F5F5F5;
    }
    ::-webkit-scrollbar {
        width: 4px; height:4px;
        background-color: #F5F5F5;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #666464;
    }
}
b {
    font-weight: 500;
}
button, a {
    outline: 0;
    border: 0;
    color:#000;
    text-decoration: none
}
.pointer {
    cursor: pointer;
}
.d {
    &-flex {
        display: flex;
    }
    &-grid {
        display: grid;
        &-1 {grid-template-columns: 1fr;}
        &-2 {grid-template-columns: 2fr;}
        &-3 {grid-template-columns: 3fr;}
        &-4 {grid-template-columns: 4fr;}
        &-5 {grid-template-columns: 5fr;}
        &-6 {grid-template-columns: 6fr;}
        &-7 {grid-template-columns: 7fr;}
        &-8 {grid-template-columns: 8fr;}
        &-9 {grid-template-columns: 9fr;}
        &-10 {grid-template-columns: 10fr;}
        &-11 {grid-template-columns: 11fr;}
        &-12 {grid-template-columns: 12fr;}
    }
    &-block {
        display: block!important;
    }
    &-inline-block {
        display: inline-block!important;
    }
}
.justify-content {
    &-between {
        justify-content: space-between;
    }
    &-center {
        justify-content: center;
    }
    &-start {
        justify-content:flex-start;
    }
    &-end {
        justify-content:flex-end;
    }
}
.align-items {
    align-items: center;
    &-center {
        align-items: center;
    }
    &-end {
        align-items:flex-end;
    }
    &-start {
        align-items:flex-start;
    }
}
.gap {
    gap: 1rem;
    &-1 {gap: 1rem;}
    &-2 {gap: 2rem;}
    &-3 {gap: 3rem;}
    &-4 {gap: 4rem;}
    &-5 {gap: 5rem;}
}
.m {
    margin-top: 1rem;
    &-0 { margin: 0!important;}
    &-1 { margin: 0.5rem!important;}
    &-2 { margin: 1rem!important;}
    &-3 { margin: 1.5rem!important;}
    &-4 { margin: 2rem!important;}
    &-5 { margin: 2.5rem!important;}
    &-6 { margin: 3rem!important;}
}
.mt {
    margin-top: 1rem;
    &-0 { margin-top: 0!important;}
    &-1 { margin-top: 0.5rem!important;}
    &-2 { margin-top: 1rem!important;}
    &-3 { margin-top: 1.5rem!important;}
    &-4 { margin-top: 2rem!important;}
    &-5 { margin-top: 2.5rem!important;}
    &-6 { margin-top: 3rem!important;}
}
.mb {
    margin-bottom: 1rem;
    &-0 { margin-bottom: 0;}
    &-1 { margin-bottom: 0.5rem;}
    &-2 { margin-bottom: 1rem;}
    &-3 { margin-bottom: 1.5rem;}
    &-4 { margin-bottom: 2rem;}
    &-5 { margin-bottom: 2.5rem;}
    &-6 { margin-bottom: 3rem;}
}
.ml {
    margin-left: 1rem!important;
    &-0 { margin-left: 0!important;}
    &-1 { margin-left: 0.5rem!important;}
    &-2 { margin-left: 1rem!important;}
    &-3 { margin-left: 1.5rem!important;}
    &-4 { margin-left: 2rem!important;}
    &-5 { margin-left: 2.5rem!important;}
    &-6 { margin-left: 3rem!important;}
}
.mr {
    margin-right: 1rem;
    &-0 { margin-right: 0;}
    &-1 { margin-right: 0.5rem;}
    &-2 { margin-right: 1rem;}
    &-3 { margin-right: 1.5rem;}
    &-4 { margin-right: 2rem;}
    &-5 { margin-right: 2.5rem;}
    &-6 { margin-right: 3rem;}
}
.pd {
    &-0 { padding: 0!important;}
    &-1 { padding: 0.5rem!important;}
    &-2 { padding: 1rem!important;}
    &-3 { padding: 1.5rem!important;}
    &-4 { padding: 2rem!important;}
    &-5 { padding: 2.5rem!important;}
    &-6 { padding: 3rem!important;}
}
.pdt {
    &-0 { padding-top: 0!important;}
    &-1 { padding-top: 0.5rem!important;}
    &-2 { padding-top: 1rem!important;}
    &-3 { padding-top: 1.5rem!important;}
    &-4 { padding-top: 2rem!important;}
    &-5 { padding-top: 2.5rem!important;}
    &-6 { padding-top: 3rem!important;}
}
.pdb {
    &-0 { padding-bottom: 0!important;}
    &-1 { padding-bottom: 0.5rem!important;}
    &-2 { padding-bottom: 1rem!important;}
    &-3 { padding-bottom: 1.5rem!important;}
    &-4 { padding-bottom: 2rem!important;}
    &-5 { padding-bottom: 2.5rem!important;}
    &-6 { padding-bottom: 3rem!important;}
}
.pdr {
    &-0 { padding-right: 0!important;}
    &-1 { padding-right: 0.5rem!important;}
    &-2 { padding-right: 1rem!important;}
    &-3 { padding-right: 1.5rem!important;}
    &-4 { padding-right: 2rem!important;}
    &-5 { padding-right: 2.5rem!important;}
    &-6 { padding-right: 3rem!important;}
}
.pdl {
    &-0 { padding-left: 0!important;}
    &-1 { padding-left: 0.5rem!important;}
    &-2 { padding-left: 1rem!important;}
    &-3 { padding-left: 1.5rem!important;}
    &-4 { padding-left: 2rem!important;}
    &-5 { padding-left: 2.5rem!important;}
    &-6 { padding-left: 3rem!important;}
}
.color {
    color:rgb(var(--black-code));
    &-theme {
        color:rgb(var(--primary-code));
    }
    &-red {
        color:rgb(var(--red-code));
    }
    &-yellow {
        color:rgb(var(--yellow-code));
    }
    &-green {
        color:rgb(var(--green-code));
    }
    &-blue {
        color:rgb(var(--blue-code));
    }
    &-grey {
        color:rgb(var(--grey-code));
    }
    &-white {
        color:#fff;
    }
    &-black {
        color:#000;
    }
}
.font {
    &-nomarl {
        font-weight: normal;
    }
    &-bold {
        font-weight: bold
    }
}
.italic {
    font-style: italic;
}
.text {
    &-center {
        text-align: center
    }
    &-left {
        text-align: left
    }
    &-right {
        text-align: right
    }
    &-xs {
        font-size: 0.75rem!important; /* 12px */
        line-height: 1rem; /* 16px */
    }
    &-sm {
        font-size: 0.875rem!important; /* 14px */
        line-height: 1.25rem; /* 20px */
    }
    &-base {
        font-size: 1rem!important; /* 16px */
        line-height: 1.5rem; /* 24px */
    }
    &-lg {
        font-size: 1.125rem!important; /* 18px */
        line-height: 1.75rem; /* 28px */
    }
    &-xl {
        font-size: 1.25rem!important; /* 20px */
        line-height: 1.75rem; /* 28px */
    }
}
.border {
    &-0 {
        border-width: 0;
    }
    &-1 {
        border-width: 1px;
    }
    &-2 {
        border-width: 2px;
    }
    &-3 {
        border-width: 3px;
    }
    &-4 {
        border-width: 4px;
    }
    &-l-0 {
        border-left-width: 0;
    }
    &-l-1 {
        border-left-width: 1px;
    }
    &-l-2 {
        border-left-width: 2px;
    }
    &-l-3 {
        border-left-width: 3px;
    }
    &-l-4 {
        border-left-width: 4px;
    }
    &-r-0 {
        border-right-width: 0;
    }
    &-r-1 {
        border-right-width: 1px;
    }
    &-r-2 {
        border-right-width: 2px;
    }
    &-r-3 {
        border-right-width: 3px;
    }
    &-r-4 {
        border-right-width: 4px;
    }
    &-t-0 {
        border-top-width: 0;
    }
    &-t-1 {
        border-top-width: 1px;
    }
    &-t-2 {
        border-top-width: 2px;
    }
    &-t-3 {
        border-top-width: 3px;
    }
    &-t-4 {
        border-top-width: 4px;
    }
    &-b-0 {
        border-bottom-width: 0;
    }
    &-b-1 {
        border-bottom-width: 1px;
    }
    &-b-2 {
        border-bottom-width: 2px;
    }
    &-b-3 {
        border-bottom-width: 3px;
    }
    &-b-4 {
        border-bottom-width: 4px;
    }
    &-black {
        border-color: rgb(0 0 0);
    }
    &-white {
        border-color: rgb(255 255 255);
    }
    &-slate-50 {
        border-color: rgb(248 250 252);
    }
    &-slate-100 {
        border-color: rgb(241 245 249);
    }
    &-slate-200 {
        border-color: rgb(226 232 240);
    }
    &-slate-300 {
        border-color: rgb(203 213 225);
    }
    &-slate-400 {
        border-color: rgb(148 163 184);
    }
    &-slate-500 {
        border-color: rgb(100 116 139);
    }
    &-gray-50 {
        border-color: rgb(249 250 251);
    }
    &-gray-100 {
        border-color: rgb(243 244 246);
    }
    &-gray-200 {
        border-color: rgb(229 231 235);
    }
    &-gray-300 {
        border-color: rgb(209 213 219);
    }
    &-gray-400 {
        border-color: rgb(156 163 175);
    }
    &-gray-500 {
        border-color: rgb(107 114 128);
    }

    &-solid {
        border-style: solid;
    }
    &-dashed {
        border-style: dashed;
    }
    &-dotted {
        border-style: dotted;
    }
    &-hidden {
        border-style: hidden;
    }
    &-none {
        border-style: none;
    }
}
.line-clamp {
    &-1 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
    }
    &-2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }
    &-3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
    }
    &-4 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
    }
    &-5 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
    }
    &-6 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 6;
    }
}
.w {
    &-100 {
        width:100%;
    }
    &-50 {
        width:50%;
    }
    &-30 {
        width:30%;
    }
    &-25 {
        width:20%;
    }
}
.h {
    &-100 {
        height:100%;
    }
    &-50 {
        height:50%;
    }
    &-30 {
        height:30%;
    }
    &-25 {
        height:20%;
    }
    &-screen {
        height: 100vh;
    }
}
.min {
    &-w-0 {
        min-width: 0;
    }
    &-w-full {
        min-width: 100%;
    }
    &-w-min {
        min-width: min-content;
    }
    &-w-max {
        min-width: max-content;
    }
    &-w-fit {
        min-width: fit-content;
    }
    &-w-100px {
        min-width: 100px;
    }
    &-w-200px {
        min-width: 200px;
    }
    &-w-300px {
        min-width: 300px;
    }
}
.container, section {
    position: relative;
}
//Avatar
.avatar {
    --avatar-size:30px;
    overflow: hidden;
    border-radius: 50%;
    height: var(--avatar-size);
    width: var(--avatar-size);
    img {
        width: 100%; height:100%; object-fit: cover;
    }
}
.heading {
    font-family: var(--font-header);
    color:var(--form-heading-color);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 5px;
        background-color: var(--form-heading-color);
    }
}
//Form Default
.form {
    --input-height:42px;
    .form-group {
        margin-bottom: 0.5rem;
    }
    .form-group + .heading {
        margin-top: 20px;
    }
    label {
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 10px;
        display: inline-block;
        font-family: var(--font-family);
    }
    input, select {
        height:var(--input-height);
    }
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        select,
        textarea,
        input {
            font-size: 16px;
        }
    }
    .form-control {
        padding:10px;
        border:1px solid rgba(0,0,0,0.1);
        box-shadow: none;
        width: 100%;
        outline: none;
        border-radius: 4px;
        appearance: none;
        line-height: 20px;
        &:focus, &:focus-visible {
            //border-color: #1663BA;
        }
        &.error {
            border-color: var(--error-message-color);
        }
    }
    .ant-select-single {
        width: 100%; min-width: 100px;
        height: var(--input-height);
        .ant-select-selector {
            border:1px solid rgba(0,0,0,0.1);
            padding:0 10px;
            height: var(--input-height);
            .ant-select-selection-search-input {
                height: var(--input-height);
            }
        }
        .ant-select-selection-item {
            line-height: var(--input-height);
        }
        .ant-select-selection-placeholder {
            line-height: var(--input-height);
        }
    }
    .ant-picker-dropdown, .react-datepicker-popper {
        z-index: 10000;
    }
    .ant-checkbox-wrapper {
        display: inline-flex!important;
    }
    .d-block .ant-checkbox-wrapper {
        width: 100%;
        margin-inline-start:0;
    }
    .ant-input-number .ant-input-number-input {
        padding-left: 0; height: 18px;
    }
    .error-message {
        color:var(--error-message-color);
        font-size:12px;
        padding-top: 5px;
    }
}
.form-search {
    .form-group {
        margin-bottom: 0;
    }
}
.form-search-mobile {
    .form-group {
        margin-bottom: 0.5rem;
    }
}
.modal-bottom {
    position: fixed;
    bottom:0; left:0; width:100%;
    padding:0 10px 0 10px;
}

//notifications
.notification-action {
    font-weight:bold; color:var(--red);
}
.notification-object {
    font-weight:bold; color:var(--blue);
}

//Search Bar
.search-bar {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    align-items: center;
    height: var(--searchBar-height);
    width: 100%;
    .form {
        display: flex; gap:10px; padding:10px 0;
        .form-group {
            margin-bottom: 0;
            min-width:200px;
        }
        .form-control {
            padding:8px 10px;
        }
    }
    .form.form-mobile {
        display: block;
        .form-group {
            margin-bottom: 10px;
        }
    }
}

//Table
.table-wrapper {
    overflow: hidden;
    //max-height: calc(100vh - 60px);
}
.table-box {
    margin-bottom: 10px;
    border: 1px solid #E7EAEF;
}
.table-action {
    align-items: center;
    >button {
        padding:0; min-width: 40px; line-height: 10px; height: 35px;
    }
}
.action-btn {
    display: block;
    padding:10px 20px;
    cursor: pointer;
    text-align: center;
}

.content-html {
    p {
        margin-bottom: 10px;
    }
    ul, ol {
        padding-left: 20px;
        margin-bottom: 10px;
    }
}

//custom antd
.group-radio-block {
    label {
        display: block!important;
    }
}

.ant-radio-button-wrapper {
    height: auto!important;
}
.ant-table-body {
    min-height:300px;
    @media(max-width:600px) {
        min-height:240px;
    }
}
.form .form-control, .ant-select:not(:where(.css-dev-only-do-not-override-k83k30).ant-select-customize-input) .ant-select-selector {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
}
.ant-pagination {
    .ant-pagination-item-active {
        border:1px solid var(--primary);
        overflow: hidden;
        a {
            background-color: var(--primary);
            color:#fff;
        }
        &:hover {
            border-color: var(--primary);
            a {
                background-color: #fff;
                color:var(--primary);
            }
        }

    }
}
.ant-pagination .ant-pagination-prev, .ant-pagination .ant-pagination-next, .ant-pagination .ant-pagination-jump-prev, .ant-pagination .ant-pagination-jump-next {
    min-width: 30px;
    height: 30px;
}
@media(max-width:600px) {
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
        display: block;
    }
    .ant-pagination .ant-pagination-item {
        margin-inline-end:0;
    }
    .ant-tabs-card {
        width: 100%;
    }

    .ant-tabs-tab {
        width: 100%;
        text-align: center;
    }
}
@media print {
    body{
        -webkit-print-color-adjust:exact;
        font-family: "Times New Roman", Courier, monospace;
    }
    img {
        display: inline;
        visibility: visible;
        -webkit-print-color-adjust: exact;
        height: 100px; width: auto;
    }
    table tr td {
        font-family: "Times New Roman", Courier, monospace;
    }
    .break-page-print {page-break-after: always;}
}
