.login {
    background-color: var(--primary);
    min-height:100vh;
    padding-top:100px;
}
.login-wrapper {
    background-color: var(--primary);
    border-radius: 20px;
    padding: 0;
    width: 400px;
    max-width: 90%;
    margin: 0 auto;
}
.login-form {
    background-color: #fff;
    padding: 50px 20px 20px 20px;
    min-height: 600px;
    border-radius: 20px;
    h3 {
        font-weight: 600;
        color: var(--primary);
        font-size: 18px;
        margin: 10px 0 10px 0;
        text-align: center;
    }
    .name {
        color: red;
        font-size: 20px;
        font-weight: 600;
    }
    h4 {
        font-weight: 400;
        color: #000;
        font-size: 14px;
        margin-bottom: 30px;
        text-align: center;
        a {
            font-weight: 500;
        }
    }
}
.register-success {
    img {
        max-width: 100px;
    }
}
@media(max-width:600px) {
    .login {
        padding-top: 30px;
        background-color: var(--primary);
    }
    .login-wrapper {
        max-width:100%;
        display: block;
    }
    .login-form {
        background-color: #fff;
        padding: 20px 20px 20px 20px;
        height: 400px;
        margin: 10px;
        border-radius: 10px;
    }
}