.card-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    @media(max-width: 800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media(max-width: 600px) {
        grid-template-columns: 1fr 1fr;
    }
    @media(max-width: 400px) {
        grid-template-columns: 1fr;
    }
}
.card {
    align-self: auto;
    font-size: 13px;
    text-align: center;
    border-radius: 10px;
    position: relative;
    .card-inner {
        position: relative;
        border-radius: .375rem;
        border-color: transparent;
        background-color: #E7EAEF;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
        .text {
            margin: 0 0 5px 0;
            font-size: 12px;
            color: #8798AD;
            text-align: left;
            font-weight: 500;
        }
        .percent {
            position: relative;
            font-weight: 700;
            font-size: 20px;
            color: var(--primary);
            span {
                color: #08F;
                line-height: 0;
                text-align: left;
                font-size: 14px;
            }
        }
        .text-mini {
            color: #08F;
            font-size: 12px;
            line-height: 25px;
            text-align: left;
            margin-top:6px;
        }
    }
}
.progress-bar-wrap {
    width: 100%;
    overflow: hidden;
    .progress-bar {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        transition: width .6s ease;
        position: relative;
        height: 8px;
        border-radius: 10px;
        background: #ddd;
        overflow: hidden;
        clear: both;
        .progress-bar-inner {
            transition: left 3s ease-in-out;
            position: absolute;
            width: 100%;
            height: 8px;
            left: 0;
            border-radius: 10px;
            background: var(--primary);
            transform: translate(-100%,0);
        }
    }
}
.block {
    border-bottom: 1px solid #ececec;
    padding: 10px;
}

.account-action {
    display: none;
    flex-wrap: wrap;
    gap:10px;
    margin-bottom: 10px;
    padding:10px;
    .account {
        font-size: 14px;
        min-width: 255px;
        &-name {
            font-weight:bold;
            font-size: 24px;
            margin-bottom: 10px;
        }
    }
    .action {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap:10px; justify-content:end; flex-wrap: wrap;
        .action-item {
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
            padding:10px 10px;
            min-width: 60px;
            box-shadow: var(--dashboard-box-shadow);
            transition: all 0.3s;
            img {
                width: 30px;
            }
            .name {
                font-size: 11px;
                a {
                    color: currentColor;
                }
            }
            &:hover {
                background-color: var(--primary);
                img {
                    filter: grayscale(100%);
                }
                .name {
                    color:#fff;
                }
            }
        }

    }
    @media (max-width: 768px) {
        display: flex;
    }
}