.order-table {
    img {
        max-width: 60px;
    }
}
.order-gird {
    .order-left {
        margin-right: 250px;
        overflow: auto;
        padding:0 10px;
    }
    .order-right {
        background: #fff;
        height: calc(100vh - 50px);
        width: 250px;
        top: 50px;
        right: 0;
        bottom: 0;
        position: fixed;
        box-shadow: 0 4px 10px rgba(0,0,0,.2);
        padding: 15px 12px 12px;
        z-index: 100;
    }
    .order-bottom {
        position: absolute;
        padding-right: 24px;
        bottom: 50px;
        width: 100%;
    }

    @media(max-width: 600px) {
        .order-left {
            margin-right: 0;
        }
        .order-right {
            position: inherit;
            width: auto;
            height: auto;
            box-shadow: none;
            padding-bottom: 80px;
        }
        .order-bottom {
            position: inherit;
            padding-right: 0;
        }
    }
}

.card {
    border-radius: 10px;
    padding:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        max-width: 100px;
    }
}
.group-radio-block {
    label {
        display: block!important;
    }
}