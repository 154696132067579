.upload-card {
    .upload-preview {
        width: 100%;
        padding-top: 70%;
        background-color: #ebebeb;
        border-radius: 10px;
        border: 1px dashed #d9d9d9;
        box-sizing: border-box;
        position: relative;
        div {
            position: absolute;
            top:0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .upload-button {
        position: relative;
        margin-bottom: 1.5em;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            z-index: 2;
            cursor: pointer;
            height: 46px;
            max-width: 100%;
        }

        button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #fff;
            background-color: rgb(var(--primary-code));
            font-size: 0.9rem;
            cursor: pointer;
            border-radius: 4px;
            border: none;
            outline: none;
            // padding: 1em;
            transition: background-color 0.4s;
            box-shadow: 0 8px 24px rgba(149, 157, 165, 0.5);

            span {
                width: 1.8em;
                height: 1.8em;
                line-height:1.8em;
                padding: 0.4em;
                background-color: #fff;
                color: #f55e30;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #f15120;
            }
        }
    }
}