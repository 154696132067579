//sidebar
.sidebar {
    max-width: 230px;
    height: 100%;
    background-color:#fff;
    .button-action {
        padding:10px;
    }
    .menu {
        text-align: left;
        .menu-item {
            a {
                display:flex;
                padding:15px 10px;
                justify-content: flex-start;
                gap:10px;
                color:#000;
                background-color: #fff;
                border-radius:0;
                font-weight: 500;
                span {
                    font-size:14px;
                    i {
                        font-size:15px;
                    }
                }
            }
            &.open {
                .menu-sub {
                    display:block;
                }
            }
            &.active {
                > a {
                    background-color: var(--primary);
                    color:#fff;
                }
            }
        }
    }
    .menu-sub {
        background-color: #e3e3e3;
        display:none;
        .icon {
            display: none;
        }
        .menu-item {
            a {
                background-color: #e3e3e3;
            }
        }
    }
}
.account-verify {
    .account-verify-inner {
        background-color: #ffeae6;
        border-radius: 10px;
    }
}
.navigation-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100%);
    z-index: 99;
    background-color: #fff;
    transition: all 0.5s;
    border-top: 1px solid #00000014;
    border-radius: 20px 20px 0 0;
    .content {
        max-width: 400px;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding:0 10px;
        .item {
            color: #676767;
            text-align: center;
            width: 80px;
            height:60px;
            padding:10px 0;
            align-items: center;
            justify-content: center;
            transition: color 0.15s ease-in;
            position: relative;
            span.icon {
                display: block;
                font-size:20px;
                line-height: 0;
                height: 26px;
            }
            span {
                display: block;
                font-size:11px;
            }
            .u-avatar {
                --avatar-size:40px;
            }
            &.active {
                color: var(--primary);
                &:after {
                    content:'';
                    display: block;
                    position: absolute;
                    top:-2px; left:0;
                    height:3px;
                    background-color:var(--primary);
                    width: 100%;
                    border-radius:10px;
                }
            }
        }
    }
}

.menus-mobile {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    -webkit-box-pack: center;
    .item {
        display: block;
        text-align: center;
        width: 100%;
        max-width: calc(100% / 2 - 20px);
        color: rgb(51, 51, 51);
        margin: 12px 10px;
        text-decoration: none;
        border-radius: 10px;
        box-shadow: rgb(17 17 26 / 5%) 0 1px 0, rgb(17 17 26 / 10%) 0 0 8px;
        position: relative;
        .icon {
            height: 50px;
            width: 50px;
            font-size: 18px;
            text-align: left;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: var(--primary);
            position: absolute;
            border-radius: 10px 0 91px 0;
            left: 0;
            top: 0;
            padding: 0 20px 10px 0;
            color: #fff;
        }
        .name {
            color: #000;
            font-weight: bold;
            text-align: center;
            margin-top: 0;
            padding: 20px 16px 20px 30px;
            font-size:14px;
        }
    }
}