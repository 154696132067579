.loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #00000096;
    z-index: 98;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction:column;
    &.noFixed {
        position:absolute;
    }
    &.noBar {
        .loader-bar {
            display: none;
        }
    }
    &.bar {
        .loader {
            display: none;
        }
    }
    &.spin {
        .loader-bar {
            display: none;
        }
        .loader {
            &::after, &::before {
                display: none
            }
        }
    }
}
.loader-box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap:20px;
}
.header {
    font-weight:900;
    font-size:30px;
    font-family: 'Fugaz One', cursive;
    color: var(--primary);
    letter-spacing: 1px;
}
:not(.spin) .loader {
    width: 48px;
    height: 40px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: inline-block;
    position: relative;
    background: var(--primary);
    border-radius: 15% 15% 35% 35%;
    .loader-text {
        color:#fff; font-size:25px; font-weight:bold;
    }
}
:not(.spin) .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 45px;
    top: 8px;
    border: 4px solid var(--primary);
    width: 16px;
    height: 20px;
    border-radius: 0 4px 4px 0;
}
:not(.spin) .loader::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 10px;
    color: var(--primary);
    top: -15px;
    left: 11px;
    box-sizing: border-box;
    animation: animloader 1s ease infinite;
}
//Loader Bar
:not(.spin) .loader-bar {
    display: block;
    position: relative;
    height: 5px;
    width: 100px;
    border: 1px solid var(--primary);
    border-radius: 10px;
    overflow: hidden;
}
:not(.spin) .loader-bar::after {
    content: '';
    width: 40%;
    height: 100%;
    background: var(--primary);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: animLoaderBar 2s linear infinite;
}
.spin {
    .loader {
        width: 48px;
        height: 48px;
        border: 5px solid #FFF;
        border-bottom-color: #FF3D00;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        background: transparent;
        .loader-text {
            display:none;
        }
    }
    .header {
        display: none;
    }
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes animloader {
    0% {
        box-shadow: 2px 0px rgba(255, 255, 255, 0), 12px 0px rgba(255, 255, 255, 0.3), 20px 0px rgba(255, 255, 255, 0);
    }
    50% {
        box-shadow: 2px -5px rgba(255, 255, 255, 0.5), 12px -3px rgba(255, 255, 255, 0.5), 20px -2px rgba(255, 255, 255, 0.6);
    }
    100% {
        box-shadow: 2px -8px rgba(255, 255, 255, 0), 12px -5px rgba(255, 255, 255, 0), 20px -5px rgba(255, 255, 255, 0);
    }
}

@keyframes animLoaderBar {
    0% {
        left: 0;
        transform: translateX(-100%);
    }
    100% {
        left: 100%;
        transform: translateX(0%);
    }
}