.wrapper {
    background-color: var(--primary);
    height: 100vh;
    margin-top: 50px;
    .wrapper-inner {
        height: 100vh;
    }
    @media (min-width:1900px) {
        .wrapper-inner {
            margin:0 auto;
        }
    }
}
.container {
    max-width:100%;
    display: flex;
}
main {
    background-color: #FBFCFF;
    min-height: calc(100vh - 50px);
    display: flex;
    @media(max-width:767px) {
        display: block;
        margin: 0;
        height: auto;
        padding-bottom: 20px;
    }
}
//Content
.content-scroll {
    overflow: auto;
    position: relative;
    width:100%;
    max-height: calc(100vh - 90px);
    @media (max-width:600px) {
        max-height: calc(100vh - 190px);
    }
}
.content {
    flex: 1 1 auto;
    min-height: 100vh;
    padding:10px;
    background-color: #e7eaef;
    border-radius: 10px;
    position: relative;
    transition: all 0.5s;
    width: calc(100% - 241px);
    @media (max-width:1300px) {
        width: calc(100% - 80px);
    }
    @media (max-width:767px) {
        max-height: none;
        width: 100%;
    }
    @media (max-width:600px) {
        padding:0;
    }
}
.content-shadow {
    border-radius: 10px;
    width:100%;
    box-shadow: 0 1rem 30px rgb(0 0 0 / 20%);
}

//section
section {
    box-shadow: 0 0 5px #E7EAEF;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    margin-bottom: 10px;
    @media (max-width:600px) {
        padding:10px;
    }
}